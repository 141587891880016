<template>
  <div>
    <base-drawer
      :title="getTitle()"
      :open="open"
      :close="close"
      @onClose="closeDrawer"
      :goBack="step > 1 && step < 10 && !showLoading"
      :back="step > 1 && step < 10 && !showLoading"
      @onBack="prevStep()"
    >
      <base-steps
        v-if="step < 10 && !showLoading"
        :steps="steps"
        :currentStep="currentStep"
      >
        <base-card v-if="step === 1">
          <base-label text="Insira o nome do criativo" />
          <base-input
            design="two"
            placeholder="Nome do criativo"
            v-model="creative.name"
            @keyup.enter="validateStep()"
          />
        </base-card>

        <base-card v-if="step === 2">
          <base-label text="Defina o remetente" />
          <base-input
            design="two"
            placeholder="Nome do remetente"
            v-model="creative.sender"
          />
        </base-card>

        <base-card v-if="step === 3">
          <base-label text="Destino" />
          <base-radio
            v-model="creative.urlType"
            family="urlTypeRadio"
            option="STANDARD"
          >
            <p class="radio-label">Endereço padrão</p>
          </base-radio>
          <base-radio
            v-model="creative.urlType"
            family="urlTypeRadio"
            option="CUSTOM"
            class="mt_15"
          >
            <p class="radio-label">Endereço personalizado</p>
          </base-radio>
          <base-input
            v-if="creative.urlType === 'CUSTOM'"
            class="mt_20"
            design="two"
            label="Insira a URL"
            v-model="creative.configs.url"
          />
        </base-card>

        <base-card v-if="step === 4">
          <base-label text="Determine o benefício" />
          <div class="grid-options">
            <div
              class="option"
              :class="{
                active: selectedBenefit === benefitTypes.CASH_DISCOUNT,
              }"
              @click="selectedBenefit = benefitTypes.CASH_DISCOUNT"
            >
              <i
                class="far fa-sack-dollar"
                v-if="selectedBenefit !== benefitTypes.CASH_DISCOUNT"
              />
              <i
                class="far fa-check"
                v-if="selectedBenefit === benefitTypes.CASH_DISCOUNT"
              />
              <p>Desconto em R$</p>
            </div>

            <div
              class="option"
              :class="{
                active: selectedBenefit === benefitTypes.PERCENTAGE_DISCOUNT,
              }"
              @click="selectedBenefit = benefitTypes.PERCENTAGE_DISCOUNT"
            >
              <i
                class="far fa-percent"
                v-if="selectedBenefit !== benefitTypes.PERCENTAGE_DISCOUNT"
              />
              <i
                class="far fa-check"
                v-if="selectedBenefit === benefitTypes.PERCENTAGE_DISCOUNT"
              />
              <p>Desconto em %</p>
            </div>

            <div
              class="option"
              :class="{ active: selectedBenefit === benefitTypes.CASHBACK }"
              @click="selectedBenefit = benefitTypes.CASHBACK"
            >
              <i
                class="far fa-hand-holding-dollar"
                v-if="selectedBenefit !== benefitTypes.CASHBACK"
              />
              <i
                class="far fa-check"
                v-if="selectedBenefit === benefitTypes.CASHBACK"
              />
              <p>Cashback</p>
            </div>

            <div
              class="option"
              :class="{
                active: selectedBenefit === benefitTypes.FREE_DELIVERY,
              }"
              @click="selectedBenefit = benefitTypes.FREE_DELIVERY"
            >
              <i
                class="far fa-motorcycle"
                v-if="selectedBenefit !== benefitTypes.FREE_DELIVERY"
              />
              <i
                class="far fa-check"
                v-if="selectedBenefit === benefitTypes.FREE_DELIVERY"
              />
              <p>Entrega grátis</p>
            </div>
          </div>
        </base-card>

        <div v-if="step === 5">
          <base-card>
            <base-label text="Insira o código do benefício" />
            <base-input
              label="Código do benefício"
              class="mb_0"
              v-model="creative.code"
              maxlength="10"
            />
            <base-message v-if="showCodeLimit" class="mt_10">
              Limite de 10 caracteres
            </base-message>
          </base-card>

          <div v-if="selectedBenefit === benefitTypes.CASHBACK">
            <base-card>
              <base-label
                text="Determine o valor do cashback e o pedido mínimo"
              />
              <div class="d-flex">
                <base-input
                  label="Valor cashback"
                  class="w-100 mb_0"
                  v-model="creative.configs.value"
                  :money-mask="StringLanguage.percentConfigs"
                  :max="8"
                />
                <base-input
                  label="Pedido mínimo"
                  class="w-100 ml_15 mb_0"
                  v-model="creative.configs.minOrder"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>

            <base-card>
              <base-label text="Determine o limite de ganho do cashback" />
              <div class="d-flex">
                <base-input
                  label="Limite de Ganho"
                  class="w-100 mb_0"
                  v-model="creative.configs.maxGain"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>

            <base-card>
              <base-label
                text="Defina a expiração de ganho e de gasto deste benefício"
              />
              <base-input
                placeholder="--- dias de expiração de ganho"
                class="mb_0"
                type="number"
                v-model="creative.configs.expiration.gain.days"
              />
              <base-input
                placeholder="--- dias de expiração de gasto"
                class="mb_0"
                type="number"
                v-model="creative.configs.expiration.spend.days"
              />
            </base-card>
          </div>

          <div v-if="selectedBenefit === benefitTypes.CASH_DISCOUNT">
            <base-card>
              <base-label
                text="Determine o valor do benefício e o pedido mínimo"
              />

              <div class="d-flex">
                <base-input
                  label="Valor"
                  class="w-100 mb_0"
                  v-model="creative.configs.value"
                  :money-mask="StringLanguage.currencyConfigs"
                />
                <base-input
                  label="Pedido mínimo"
                  class="w-100 ml_15 mb_0"
                  v-model="creative.configs.minOrder"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>
          </div>

          <div v-if="selectedBenefit === benefitTypes.PERCENTAGE_DISCOUNT">
            <base-card>
              <base-label
                text="Determine o valor do benefício e o pedido mínimo"
              />

              <div class="d-flex">
                <base-input
                  label="Valor"
                  class="w-100 mb_0"
                  v-model="creative.configs.value"
                  :money-mask="StringLanguage.percentConfigs"
                  :max="8"
                />
                <base-input
                  label="Pedido mínimo"
                  class="w-100 ml_15 mb_0"
                  v-model="creative.configs.minOrder"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>
          </div>

          <div v-if="selectedBenefit === benefitTypes.FREE_DELIVERY">
            <base-card>
              <base-label text="Determine o valor do pedido mínimo" />
              <base-input
                label="Pedido mínimo"
                class="mb_0"
                v-model="creative.configs.minOrder"
                :money-mask="StringLanguage.currencyConfigs"
              />
            </base-card>
          </div>
          <div v-if="selectedBenefit !== benefitTypes.CASHBACK">
            <base-card>
              <base-label text="Defina a expiração do benefício" />
              <base-input
                placeholder="--- dias"
                v-model="creative.configs.expiration.gain.days"
                :thousandSeparator="false"
                :moneyMask="StringLanguage.timeConfigs.days"
              />
              <base-message>
                A expiração deste benefício inicia a partir do momento que o
                usuário o recebe.
              </base-message>
            </base-card>
          </div>
        </div>

        <base-card v-if="step === 6">
          <base-label text="Onde o benefício poderá ser utilizado?" />

          <base-radio
            v-model="creative.useType"
            family="useType"
            option="offline"
          >
            <p class="radio-label">Consumo no local</p>
          </base-radio>
          <base-radio
            v-model="creative.useType"
            family="useType"
            option="online"
            class="mt_15"
          >
            <p class="radio-label">Delivery</p>
          </base-radio>
          <base-radio
            v-model="creative.useType"
            family="useType"
            option="both"
            class="mt_15"
          >
            <p class="radio-label">Ambos</p>
          </base-radio>
        </base-card>

        <div v-if="step === 7">
          <base-card>
            <use-of-tags />
          </base-card>

          <base-card>
            <base-label text="Escreva a mensagem que será enviada" />
            <base-textarea
              label="Mensagem"
              v-model="messages.creative.withGaps"
              :minHeight="100"
              @input="replaceGaps($event, 'creative')"
            />
            <div class="d-flex justify-end">
              <p class="count-characters">
                <span class="number">{{ getTotalSMS('creative') }}</span>
                <span class="type">
                  &nbsp;{{
                    getTotalSMS('creative') > 1 ? 'mensagens' : 'mensagem'
                  }}
                </span>

                <span class="number">{{ getTotalChars('creative') }}</span>
                <span class="type">&nbsp;caracteres</span>
              </p>
            </div>

            <base-message
              class="mt_20 mb_50"
              v-if="getTotalSMS('creative') > 1"
            >
              A cada 160 caracteres utilizados é realizado 1 disparo. Na
              mensagem definida nesse momento, 2 ou mais disparos serão
              realizados. Mas não se preocupe, seu cliente verá tudo como uma
              mensagem única.
            </base-message>

            <base-label text="Pré-visualização" />
            <pre
              class="preview creative"
              v-html="messages.creative.preview"
            ></pre>
          </base-card>

          <test-shooting-card
            :message="messages.creative.withGaps"
            :sender="creative.sender ? `${creative.sender}: ` : ''"
            :url="{ type: creative.urlType, value: creative.configs.url }"
            :beneficio="{
              type: selectedBenefit,
              value: `${creative.code}-XXX`,
            }"
            :valorrecebido="0"
          />
        </div>

        <div v-if="step === 8">
          <div v-if="!showMessageGain">
            <base-card>
              <base-label
                :text="
                  selectedBenefit === benefitTypes.CASHBACK
                    ? 'Deseja ativar lembrete de expiração de ganho?'
                    : 'Deseja ativar lembrete de expiração?'
                "
              />

              <base-radio
                v-model="showExpReminderFields"
                family="expReminderRadio"
                option="yes"
              >
                <p class="radio-label">Sim</p>
              </base-radio>
              <base-radio
                v-model="showExpReminderFields"
                family="expReminderRadio"
                option="no"
                class="mt_15"
              >
                <p class="radio-label">Não</p>
              </base-radio>

              <div v-if="showExpReminderFields === 'yes'">
                <div class="exp-reminder-box">
                  <base-label
                    text="
                  Defina o período para lembrete de expiração
                "
                  />
                  <base-input
                    design="three"
                    placeholder="--- dias antes da expiração"
                    type="number"
                    v-model="creative.configs.expiration.gain.remindBeforeDays"
                  />

                  <base-label text="Determine a hora do envio" class="mt_30" />
                  <base-select
                    design="three"
                    title="Selecione a faixa"
                    :items="timeIntervalOptions"
                    v-model="creative.configs.expiration.gain.sendTime"
                    class="mt_20"
                  />
                </div>
              </div>
            </base-card>
          </div>

          <div v-if="showMessageGain">
            <base-card>
              <div
                class="reminderType"
                v-if="selectedBenefit === benefitTypes.CASHBACK"
              >
                Lembrete de expiração de ganho
              </div>
              <use-of-tags />
            </base-card>

            <base-card>
              <base-label
                :text="
                  selectedBenefit === benefitTypes.CASHBACK
                    ? 'Escreva a mensagem que será enviada na expiração de ganho'
                    : 'Escreva a mensagem que será enviada no lembrete de expiração'
                "
              />
              <base-textarea
                label="Mensagem"
                v-model="messages.gain.withGaps"
                :minHeight="100"
                @input="replaceGaps($event, 'gain')"
              />
              <div class="d-flex justify-end">
                <p class="count-characters">
                  <span class="number">{{ getTotalSMS('gain') }}</span>
                  <span class="type">
                    &nbsp;{{
                      getTotalSMS('gain') > 1 ? 'mensagens' : 'mensagem'
                    }}
                  </span>

                  <span class="number">{{ getTotalChars('gain') }}</span>
                  <span class="type">&nbsp;caracteres</span>
                </p>
              </div>

              <base-message class="mt_20 mb_50" v-if="getTotalSMS('gain') > 1">
                A cada 160 caracteres utilizados é realizado 1 disparo. Na
                mensagem definida nesse momento, 2 ou mais disparos serão
                realizados. Mas não se preocupe, seu cliente verá tudo como uma
                mensagem única.
              </base-message>

              <base-label text="Pré-visualização" />
              <pre class="preview gain" v-html="messages.gain.preview"></pre>
            </base-card>

            <test-shooting-card
              :message="messages.gain.withGaps"
              :sender="creative.sender ? `${creative.sender}: ` : ''"
              :url="{ type: creative.urlType, value: creative.configs.url }"
              :beneficio="{
                type: selectedBenefit,
                value: `${creative.code}-XXX`,
              }"
              :valorrecebido="0"
            />
          </div>
        </div>

        <div v-if="step === 9 && selectedBenefit === benefitTypes.CASHBACK">
          <div v-if="!showMessageSpend">
            <base-card>
              <base-label
                text="Deseja ativar lembrete de expiração de gasto?"
              />

              <base-radio
                v-model="showExpReminderSpendFields"
                family="expReminderSpendRadio"
                option="yes"
              >
                <p class="radio-label">Sim</p>
              </base-radio>
              <base-radio
                v-model="showExpReminderSpendFields"
                family="expReminderSpendRadio"
                option="no"
                class="mt_15"
              >
                <p class="radio-label">Não</p>
              </base-radio>

              <div v-if="showExpReminderSpendFields === 'yes'">
                <div class="exp-reminder-box">
                  <base-label
                    text="Defina o período para lembrete de expiração de gasto"
                  />
                  <base-input
                    design="three"
                    placeholder="--- dias antes da expiração de gasto"
                    type="number"
                    v-model="creative.configs.expiration.spend.remindBeforeDays"
                  />

                  <base-label text="Determine a hora do envio" class="mt_30" />
                  <base-select
                    design="three"
                    title="Selecione a faixa"
                    :items="timeIntervalOptions"
                    v-model="creative.configs.expiration.spend.sendTime"
                    class="mt_20"
                  />
                </div>
              </div>
            </base-card>
          </div>

          <div v-if="showMessageSpend">
            <base-card>
              <div class="reminderType">Lembrete de expiração de gasto</div>
              <use-of-tags />
            </base-card>

            <base-card>
              <base-label
                text="Escreva a mensagem que será enviada na expiração de gasto"
              />
              <base-textarea
                label="Mensagem"
                v-model="messages.spend.withGaps"
                :minHeight="100"
                @input="replaceGaps($event, 'spend')"
              />
              <div class="d-flex justify-end">
                <p class="count-characters">
                  <span class="number">{{ getTotalSMS('spend') }}</span>
                  <span class="type">
                    &nbsp;{{
                      getTotalSMS('spend') > 1 ? 'mensagens' : 'mensagem'
                    }}
                  </span>

                  <span class="number">{{ getTotalChars('spend') }}</span>
                  <span class="type">&nbsp;caracteres</span>
                </p>
              </div>
              <base-message class="mt_20 mb_50" v-if="getTotalSMS('spend') > 1">
                A cada 160 caracteres utilizados é realizado 1 disparo. Na
                mensagem definida nesse momento, 2 ou mais disparos serão
                realizados. Mas não se preocupe, seu cliente verá tudo como uma
                mensagem única.
              </base-message>

              <base-label text="Pré-visualização" />
              <pre class="preview spend" v-html="messages.spend.preview"></pre>
            </base-card>

            <test-shooting-card
              :message="messages.spend.withGaps"
              :sender="creative.sender ? `${creative.sender}: ` : ''"
              :url="{ type: creative.urlType, value: creative.configs.url }"
              :beneficio="{
                type: selectedBenefit,
                value: `${creative.code}-XXX`,
              }"
              :valorrecebido="0"
            />
          </div>
        </div>
      </base-steps>

      <div v-if="showLoading" class="loading">
        <base-loading />
      </div>

      <div v-if="step === 10">
        <base-card>
          <div class="success">
            <i class="far fa-check" />
            <p>Agora você pode voltar para os detalhes.</p>
          </div>
        </base-card>
      </div>

      <template slot="footer">
        <base-button
          class="w-100"
          @click="nextStep()"
          v-if="showNext()"
          :disabled="validate"
        >
          Próximo
        </base-button>
        <div v-if="!showLoading">
          <base-button
            class="w-100 mt_20"
            design="four"
            v-if="step === 2"
            @click="nextStep(), (creative.sender = null)"
          >
            Continuar sem remetente
          </base-button>

          <base-button
            class="w-100 mt_20"
            design="four"
            v-if="step === 3"
            @click="nextStep(), (creative.configs.url = null)"
          >
            Continuar sem URL
          </base-button>

          <base-button
            class="w-100 mt_20"
            design="four"
            v-if="step === 4"
            @click="nextStep(7, 5), (selectedBenefit = null)"
          >
            Não conceder benefícios
          </base-button>

          <base-button
            class="w-100"
            @click="save()"
            :disabled="validate"
            v-if="showFinishCreateCreative()"
          >
            Concluir criação de criativo
          </base-button>

          <base-button class="w-100" v-if="step === 10" @click="closeDrawer">
            Voltar para detalhes
          </base-button>
        </div>
      </template>
    </base-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import getDate from '@/mixins/getDate.js';

import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseSelect from '@/components/Select';
import BaseRadio from '@/components/Radio';
import BaseBox from '@/components/Box';
import BaseMessage from '@/components/Message';
import BaseDrawer from '@/components/Drawer';
import BaseTextarea from '@/components/Textarea';
import BaseSteps from '@/components/Steps';
import BaseCard from '@/components/Card';
import BaseLoading from '@/components/Loading';
import UseOfTags from '@/containers/UseOfTags';
import TestShootingCard from '@/containers/TestShootingCard.vue';

import { CampaignAPI } from '@/api/CampaignAPI';
import { EnvironmentsAPI } from '@/api/Environments';
import { TestShootingAPI, TestShootingSmsBody } from '@/api/TestShootingAPI';

const components = {
  BaseButton,
  BaseTitle,
  BaseLabel,
  BaseInput,
  BaseRadio,
  BaseDrawer,
  BaseTextarea,
  BaseSteps,
  BaseSteps,
  BaseCard,
  BaseLoading,
  BaseBox,
  BaseMessage,
  BaseSelect,
  UseOfTags,
  TestShootingCard,
};

const benefitTypes = {
  CASHBACK: 'CASHBACK',
  CASH_DISCOUNT: 'CASH_DISCOUNT',
  PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
  FREE_DELIVERY: 'FREE_DELIVERY',
};

const useTypes = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  BOTH: 'both',
};

const urlTypes = {
  STANDARD: 'STANDARD',
  CUSTOM: 'CUSTOM',
};


const defaultPhoneParam = '?q=5599999999999';
const defaultPin = Math.random().toString(36).slice(4, 7).toUpperCase();
const maxCharsPerMessage = 160;

export default {
  mixins: [getDate],
  components,
  props: {
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    campaignId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showMessageGain: false,
      showMessageSpend: false,
      environment: null,
      validate: true,
      finished: false,
      benefitTypes,
      useTypes,
      urlTypes,
      configurations: null,
      defaultShortenedUrl: '',
      defaultWalletUrl: '',
      defaultPhoneParam,
      defaultPin,
      selectedTab: 0,
      selectedBenefit: null,
      showExpReminderFields: false,
      showExpReminderSpendFields: false,
      timeIntervalOptions: this.getTimeIntervalOptions(),
      isWaitingSending: false,
      remainTime: 0,
      testPhone: {
        ddd: '',
        number: '',
      },
      messages: {
        creative: {
          withGaps: '',
          preview: '',
          final: '',
        },
        gain: {
          withGaps: '',
          preview: '',
          final: '',
        },
        spend: {
          withGaps: '',
          preview: '',
          final: '',
        },
      },
      steps: 6,
      step: 1,
      currentStep: 1,
      showLoading: false,
      showCodeLimit: false,
      creative: {
        name: null,
        type: null,
        sender: null,
        urlType: null,
        useType: null,
        code: null,
        pin: null,
        text_message: null,
        configs: {
          url: null,
          value: null,
          minOrder: null,
          maxGain: null,
          expiration: {
            gain: {
              days: null,
              remindBeforeDays: null,
              message: null,
              sendTime: null,
            },
            spend: {
              days: null,
              remindBeforeDays: null,
              message: null,
              sendTime: null,
            },
          },
        },
      },
    };
  },
  async beforeMount() {
    const envInfo = await EnvironmentsAPI.me();
    this.environment = envInfo.getData({});

    const envConfigurations = await EnvironmentsAPI.getConfigurations();
    this.configurations = envConfigurations.getData({});
    this.defaultShortenedUrl =
      this.configurations['short-urls'] &&
      this.configurations['short-urls'].rawShortWallet
        ? this.configurations['short-urls'].rawShortWallet
        : 'https://4d5c.short.gy/nDVNA7';

    this.defaultWalletUrl =
      this.configurations.wallet &&
      this.configurations.wallet.configs &&
      this.configurations.wallet.configs.walletDomain
        ? this.configurations.wallet.configs.walletDomain
        : `${process.env.VUE_APP_URL_WALLET}/${this.environment.alias || ''}`;
  },

  methods: {
    showFinishCreateCreative() {
      if (this.step === 8 && this.selectedBenefit !== benefitTypes.CASHBACK) {
        if (this.showExpReminderFields === 'no') return true;
        if (this.showExpReminderFields === 'yes' && this.showMessageGain)
          return true;
      }

      if (this.step === 9 && this.selectedBenefit === benefitTypes.CASHBACK) {
        if (this.showExpReminderSpendFields === 'no') return true;
        if (this.showExpReminderSpendFields === 'yes' && this.showMessageSpend)
          return true;
      }

      return false;
    },
    showNext() {
      if (!this.finished) {
        if (
          this.step === 8 &&
          this.selectedBenefit !== benefitTypes.CASHBACK &&
          this.showMessageGain
        )
          return false;

        if (this.step === 9 && this.showMessageSpend) return false;

        if (this.step < 9 && this.showExpReminderFields !== 'no') return true;

        if (this.step <= 7 && !this.selectedBenefit) return true;

        if (
          this.step < 10 &&
          this.selectedBenefit === benefitTypes.CASHBACK &&
          this.showExpReminderSpendFields !== 'no'
        )
          return true;
      }

      return false;
    },
    validateStep() {
      const keysValid = Object.keys(this.creative);

      if (this.step === 1 && (this.creative.name || []).length >= 4)
        return false;

      if (
        this.step === 2 &&
        keysValid.includes('sender') &&
        (this.creative.sender || []).length > 1
      )
        return false;

      if (this.step === 3) {
        if (this.creative.urlType === 'standard') return;

        const testeLink =
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
            this.creative.configs.url
          );
        return !testeLink;
      }

      if (this.step === 4 && (this.selectedBenefit || []).length > 0)
        return false;

      if (this.step === 5) {
        if (
          +this.creative.configs.value > 0 &&
          (this.creative.code || []).length > 0 &&
          this.creative.configs.expiration.gain.days > 0
        )
          return false;

        if (
          this.selectedBenefit === benefitTypes.FREE_DELIVERY &&
          (this.creative.code || []).length > 0 &&
          this.creative.configs.expiration.gain.days > 0
        )
          return false;
      }

      if (this.step === 6 && (this.selectedBenefit || []).length > 0)
        return !this.creative.useType;

      if (this.step === 7) {
        this.checkIfMessageHasTheTags('creative');
      }

      if (
        this.step === 8 &&
        this.showExpReminderFields === 'yes' &&
        this.showMessageGain
      ) {
        return true;
      }

      if (
        this.step === 8 &&
        this.showExpReminderFields === 'yes' &&
        (this.creative.configs.expiration.gain.remindBeforeDays || []).length >
          0 &&
        (this.creative.configs.expiration.gain.sendTime || []).length > 0
      )
        return false;

      if (this.step === 8 && this.showExpReminderFields === 'no') return false;

      if (
        this.step === 9 &&
        this.showExpReminderSpendFields === 'yes' &&
        this.showMessageSpend
      ) {
        return true;
      }

      if (
        this.step === 9 &&
        this.showExpReminderSpendFields === 'yes' &&
        (this.creative.configs.expiration.spend.remindBeforeDays || []).length >
          0 &&
        (this.creative.configs.expiration.spend.sendTime || []).length > 0
      )
        return false;

      if (this.step === 9 && this.showExpReminderSpendFields === 'no')
        return false;

      return true;
    },
    closeDrawer() {
      this.indexValidate = 0;
      this.step = 1;
      this.currentStep = 1;
      this.$emit('onClose');

      this.mountNullObject(this.creative);

      this.showMessageGain = false;
      this.showMessageSpend = false;
      this.showExpReminderFields = false;
      this.showExpReminderSpendFields = false;
    },
    mountNullObject(data) {
      this.messages.creative = {
        withGaps: '',
        preview: '',
        final: '',
      };
      this.messages.gain = {
        withGaps: '',
        preview: '',
        final: '',
      };
      this.messages.spend = {
        withGaps: '',
        preview: '',
        final: '',
      };

      const props = Object.keys(data);
      const objectProps = ['configs', 'expiration', 'gain', 'spend'];

      const object = data;

      props.forEach((key) => {
        let value;

        if (objectProps.includes(key)) {
          value = this.mountNullObject(data[key]);
        } else {
          value = null;
        }

        object[key] = value;
      });

      return object;
    },
    replaceGaps(event, type) {
      const { code, sender } = this.creative;

      this.messages[type].withGaps = event.replaceAll(
        `<benefício>`,
        `<beneficio>`
      );

      const hasSender = sender ? `${sender}: ` : '';
      const hasCode = code ? `${code}-${this.defaultPin}` : '';

      const gapColors = event
        .replaceAll(
          `<link>`,
          `<span style="color:#6161ff">${
            this.creative.configs.url || this.defaultShortenedUrl
          }</span>`
        )
        .replaceAll(
          '<benefício>',
          `<span style="color:#6161ff">${hasCode}</span>`
        )
        .replaceAll(
          '<beneficio>',
          `<span style="color:#6161ff">${hasCode}</span>`
        )
        .replace(/(<\/?(?:span)[^>]*>)|<[^>]+>/gi, '$1');

      this.messages[type].preview = `${hasSender}${gapColors}`;

      const messagePreview = event
        .replaceAll('<link>', this.creative.configs.url || this.defaultShortenedUrl)
        .replaceAll(`<benefício>`, hasCode)
        .replaceAll(`<beneficio>`, hasCode)
        .replace(/(<\/?(?:span)[^>]*>)|<[^>]+>/gi, '$1');

      this.messages[type].final = `${hasSender}${messagePreview}`;
    },
    getTotalChars(type) {
      return this.messages[type].final.length;
    },
    getTotalSMS(type) {
      return Math.ceil(this.messages[type].final.length / maxCharsPerMessage);
    },
    checkIfMessageHasTheTags(type) {
      const { code } = this.creative;
      const { url } = this.creative.configs;

      const hasUrl = url ? `${url}` : '';
      const hasCode = code ? `${code}-${this.defaultPin}` : '';

      this.validate = !(
        this.messages[type].final.includes(hasCode) &&
        this.messages[type].final.includes(hasUrl)
      );
    },
    save() {
      this.showLoading = true;

      const payload = JSON.parse(JSON.stringify(this.creative));

      payload.text_message = `${
        this.creative.sender ? `${this.creative.sender}: ` : ''
      }${this.messages.creative.withGaps}`;

      payload.configs.expiration.gain.message =
        this.showExpReminderFields === 'yes'
          ? `${this.creative.sender ? `${this.creative.sender}: ` : ''}${
              this.messages.gain.withGaps
            }`
          : null;

      payload.configs.expiration.spend.message =
        this.showExpReminderFields === 'yes' &&
        this.selectedBenefit === benefitTypes.CASHBACK
          ? `${this.creative.sender ? `${this.creative.sender}: ` : ''}${
              this.messages.spend.withGaps
            }`
          : null;

      if (this.selectedBenefit !== benefitTypes.CASHBACK)
        payload.configs.expiration.spend = null;

      payload.type =
        Object.keys(benefitTypes).find(
          (key) => benefitTypes[key] === this.selectedBenefit
        ) || '';

      payload.useType =
        Object.keys(useTypes).find(
          (key) => useTypes[key] === this.creative.useType
        ) || 'BOTH';

      payload.urlType =
        Object.keys(urlTypes).find(
          (key) => urlTypes[key] === this.creative.urlType
        ) || '';

      payload.configs.maxGain = parseFloat(payload.configs.maxGain) || null;
      payload.configs.minOrder = parseFloat(payload.configs.minOrder) || null;
      payload.configs.value = parseFloat(payload.configs.value) || 0;

      payload.configs.expiration.gain.days =
        parseInt(payload.configs.expiration.gain.days, 10) || 0;

      payload.configs.expiration.gain.remindBeforeDays =
        parseInt(payload.configs.expiration.gain.remindBeforeDays, 10) || null;

      if (this.selectedBenefit === benefitTypes.CASHBACK) {
        payload.configs.expiration.spend.days =
          parseInt(payload.configs.expiration.spend.days, 10) || null;

        payload.configs.expiration.spend.remindBeforeDays =
          parseInt(payload.configs.expiration.spend.remindBeforeDays, 10) ||
          null;
      }

      CampaignAPI.createCreative(this.$props.campaignId, payload)
        .then((response) => {
          if (response.getErrors([]).length > 0) {
            this.showLoading = false;
            this.finished = false;
            return;
          }
          this.$emit('save', {
            ...response.getData({}),
            open: false,
          });
          this.finished = null;
          this.showExpReminderFields = null;
          this.selectedBenefit = null;
          this.showFieldsBenefit = false;
          this.showLoading = false;
          this.step = 10;
        })
        .catch(() => {
          this.showLoading = false;
          this.finished = false;
        });
    },
    getTitle() {
      if (this.step === 10) {
        return 'Criativo adicionado com sucesso!';
      }

      if (this.selectedBenefit) {
        let title = '';

        switch (this.selectedBenefit) {
          case benefitTypes.CASHBACK:
            title = '- Desconto em cashback';
            break;
          case benefitTypes.CASH_DISCOUNT:
            title = '- Desconto em R$';
            break;
          case benefitTypes.PERCENTAGE_DISCOUNT:
            title = '- Desconto em %';
            break;
          case benefitTypes.FREE_DELIVERY:
            title = '- Entrega grátis';
            break;
        }

        return `Adicionar um criativo ${title}`;
      }

      return 'Adicionar um criativo';
    },
    getTimeIntervalOptions() {
      const horaries = [];

      for (let i = 0; i <= 22; i++) {
        horaries.push({
          value: `${String(i).padStart(2, '0')}:00`,
          text: `${String(i).padStart(2, '0')}h - ${String(i + 1).padStart(
            2,
            '0'
          )}h`,
        });
      }

      return horaries;
    },
    nextStep(step, currentStep) {
      if (step) {
        this.step = step;
        this.currentStep = currentStep;
        this.validate = this.validateStep();
        return false;
      }

      if (this.step === 8 && this.showExpReminderFields === 'yes') {
        if (!this.showMessageGain) {
          this.showMessageGain = true;
          this.validate = this.validateStep();
          return false;
        }
      }

      if (this.step === 9 && this.showExpReminderSpendFields === 'yes') {
        if (!this.showMessageSpend) {
          this.showMessageSpend = true;
          this.validate = this.validateStep();
          return false;
        }
      }

      this.step += 1;
      this.currentStep += 1;
      this.validate = this.validateStep();
    },
    prevStep() {
      if (this.step === 7 && !this.selectedBenefit) {
        this.step = 4;
        this.currentStep = 4;
        this.validate = this.validateStep();
        return false;
      }

      if (this.step === 8 && this.showExpReminderFields === 'yes') {
        if (this.showMessageGain) {
          this.showMessageGain = false;
          this.validate = this.validateStep();
          return false;
        }
      }

      if (this.step === 9 && this.showExpReminderSpendFields === 'yes') {
        if (this.showMessageSpend) {
          this.showMessageSpend = false;
          this.validate = this.validateStep();
          return false;
        }
      }

      this.step -= 1;
      this.currentStep -= 1;
      this.finished = false;
      this.validate = this.validateStep();
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    async sendSmsTest() {
      const { code, urlType } = this.creative;
      const { url } = this.creative.configs;

      this.isWaitingSending = true;
      const response = await TestShootingAPI.postSms(
        new TestShootingSmsBody({
          phone: `${this.testPhone.ddd}${this.testPhone.phone}`,
          message: this.messages.creative.final,
          replacements: {
            url: { type: urlType, value: url },
            beneficio: { type: this.selectedBenefit, value: `${code}-xxx` },
            valorrecebido: 0,
          },
        })
      );

      if (response.getCode(500) != 200) {
        alert('Ocorreu um erro ao disparar a mensagem de teste');
        this.isWaitingSending = false;
        return;
      }

      this.remainTime = 180;
      const intervalId = setInterval(() => {
        this.remainTime--;
        if (this.remainTime == 0) {
          clearInterval(intervalId);
          this.isWaitingSending = false;
        }
      }, 1000);
    },
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    showExpReminderFields: {
      handler() {
        this.validate = this.validateStep();
        this.showFinishCreateCreative();
        this.showNext();
      },
    },
    showExpReminderSpendFields: {
      handler() {
        this.validate = this.validateStep();
        this.showFinishCreateCreative();
        this.showNext();
      },
    },
    selectedBenefit: {
      handler() {
        if (this.selectedBenefit === benefitTypes.CASHBACK) {
          this.steps = 9;
        }

        if (this.selectedBenefit !== benefitTypes.CASHBACK) {
          this.steps = 8;
        }

        if (!this.selectedBenefit) {
          this.steps = 6;
        }

        this.validate = this.validateStep();
      },
      deep: true,
    },
    creative: {
      handler(newValue) {
        if (this.selectedBenefit === benefitTypes.CASHBACK) {
          this.steps = 9;
        }

        if (this.selectedBenefit !== benefitTypes.CASHBACK) {
          this.steps = 8;
        }

        if (!this.selectedBenefit) {
          this.steps = 6;
        }
        this.validate = this.validateStep();
      },
      deep: true,
    },
    open: {
      handler(newValue) {
        this.validate = this.validateStep();
      },
      deep: true,
    },
    'creative.code': {
      handler() {
        if (this.creative.code) {
          this.creative.code.toUpperCase().replace(/[^A-z0-9]|_/gi, '');

          this.showCodeLimit = this.creative.code.length === 10;
        }
      },
    },
    'creative.urlType': {
      handler() {
        if (this.creative.urlType === urlTypes.STANDARD)
          this.creative.configs.url = this.defaultWalletUrl;
      },
    },
    'messages.creative.final': {
      handler() {
        this.checkIfMessageHasTheTags('creative');
      },
    },
    'messages.gain.final': {
      handler() {
        this.checkIfMessageHasTheTags('gain');
      },
    },
    'messages.spend.final': {
      handler() {
        this.checkIfMessageHasTheTags('spend');
      },
    },
  },
};
</script>
<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.exp-reminder-box {
  padding: 20px 20px 10px;
  background: #2a3034;
  border-radius: 10px;
  margin-top: 25px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}
.success {
  background: #2d3338;
  border-radius: 10px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success i {
  color: #82d997;
  font-size: 30px;
  margin-bottom: 25px;
}

.success p {
  color: rgba(204, 204, 204, 0.75);
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

.reminderType {
  background: rgba(27, 31, 34, 0.37);
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.count-characters {
  background: rgba(53, 61, 66, 0.33);
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.count-characters .number {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(177, 177, 178, 0.9);
}

.count-characters .type {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.85);
}

.count-characters .type:nth-child(2) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
}

.preview {
  background: #222629;
  border-radius: 15px;
  padding: 15px;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #aaaaaa;
  min-height: 90px;
  word-break: break-all;
  white-space: break-spaces;
}

.enter-phone {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.fire-test-message {
  background: rgba(46, 52, 56, 0.3);
  border-radius: 10px;
  padding: 20px;
}
</style>
