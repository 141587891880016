<template>
  <div>
    <base-box class="creative" v-for="creative in items" :key="creative.id">
      <div class="header">
        <p>{{ creative.name }}</p>

        <div class="d-flex align-center">
          <span class="value-sales">
            Valor em vendas:
            <strong>{{
              formatCurrency(creative.conversions_total || 0)
            }}</strong>
          </span>

          <span class="buttons-header">
            <base-button
              design="four mini icon"
              v-if="creative.open"
              @click="deleteCreative(creative.id)"
            >
              <i class="fal fa-trash-alt" />
            </base-button>

            <div class="open">
              <i
                class="far fa-chevron-down"
                :class="{ rotate: creative.open }"
                @click="creative.open = !creative.open"
              />
            </div>
          </span>
        </div>
      </div>

      <div class="content" :class="creative.open ? 'opened' : 'closed'">
        <div class="infos" :class="{ opened: creative.open }">
          <div class="info">
            <p>Código do benefício</p>
            <span>
              {{ creative.code }}
              &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
              <strong>{{ creative.pin }}</strong>
            </span>
          </div>
          <div class="info">
            <p>Valor do benefício</p>
            <span>{{
              benefitTypes[creative.type] === benefitTypes.CASH_DISCOUNT
                ? formatCurrency(creative.configs.value)
                : `${creative.configs.value}%`
            }}</span>
          </div>
        </div>
        <div class="infos">
          <div class="info">
            <p>Valor do pedido mínimo</p>
            <span>{{ formatCurrency(creative.configs.minOrder) }}</span>
          </div>
          <div class="info">
            <p>URL de acesso</p>
            <a href="#" target="_blank">{{ creative.configs.url }}</a>
          </div>
        </div>
        <div class="infos" v-if="creative.configs.expiration !== null">
          <div class="info">
            <p>Expiração de ganho</p>
            <span>{{ creative.configs.expiration.gain.days }} dias</span>
          </div>

          <div class="info">
            <p>Lembrete de expiração de ganho</p>
            <span v-if="creative.configs.expiration.gain.remindBeforeDays"
              >{{ creative.configs.expiration.gain.remindBeforeDays }} dias
              antes</span
            >
            <span v-else>Não há</span>
          </div>
        </div>
        <div
          v-if="benefitTypes[creative.type] === benefitTypes.CASHBACK"
          class="infos"
        >
          <div class="info">
            <p>Expiração de gasto</p>
            <span>{{ creative.configs.expiration.spend.days }} dias</span>
          </div>

          <div class="info">
            <p>Lembrete de expiração de gasto</p>
            <span v-if="creative.configs.expiration.spend.remindBeforeDays"
              >{{ creative.configs.expiration.spend.remindBeforeDays }} dias
              antes</span
            >
            <span v-else>Não há</span>
          </div>
        </div>
      </div>

      <p class="message">"{{ creative.text_message }}"</p>

      <div class="funnels">
        <div class="funnel" :class="{ active: creative.sent }">
          <div class="funnel-name">
            <p>Envios</p>
          </div>
          <div class="funnel-value">
            <p v-if="creative.sent">{{ creative.sent }}</p>
            <p v-else class="empty">0</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="funnel" :class="{ active: creative.clicks }">
          <div class="funnel-name">
            <p>Cliques</p>
          </div>
          <div class="funnel-value">
            <p v-if="creative.clicks">{{ creative.clicks }}</p>
            <p v-else class="empty">0</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="funnel" :class="{ active: creative.conversions_quantity }">
          <div class="funnel-name">
            <p>Conversões</p>
          </div>
          <div class="funnel-value">
            <p v-if="creative.conversions_quantity">
              {{ creative.conversions_quantity }}
            </p>
            <p v-else class="empty">0</p>
          </div>
        </div>
      </div>
    </base-box>

    <infinite-loading @infinite="($state) => loadCreatives($state)">
      <span slot="spinner">
        <base-loading />
      </span>
      <span slot="no-more"></span>
      <span slot="no-results">
        <base-empty
          v-if="items.length === 0"
          title="Você ainda não tem criativos para essa campanha."
          message="Todas os criativos irão aparecer aqui."
        >
          <template slot="image">
            <i class="far fa-bullhorn" />
          </template>
        </base-empty>
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseBox from '@/components/Box';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseBadge from '@/components/Badge';
import BaseEmpty from '@/components/Empty';
import BaseLoading from '@/components/Loading';

import Utils from '@/utils';
import { CampaignAPI } from '@/api/CampaignAPI';

const components = {
  BaseButton,
  BaseTitle,
  BaseBox,
  BaseLabel,
  BaseInput,
  BaseDrawer,
  BaseCard,
  BaseBadge,
  BaseEmpty,
  BaseLoading,
  InfiniteLoading,
};

const benefitTypes = {
  CASHBACK: 'cashback',
  CASH_DISCOUNT: 'discountMoney',
  PERCENTAGE_DISCOUNT: 'discountPercent',
  FREE_DELIVERY: 'freeDelivery',
};

export default {
  components,
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    savedItems: {
      type: Array,
      default: () => [],
    },
    searchString: {
      type: String,
      default: '',
    },
    isTyping: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      benefitTypes,
      items: [],
      creativesFilters: {
        name: '',
      },
      pagination: {
        page: 1,
        limit: 12,
      },
    };
  },
  methods: {
    formatCurrency(value) {
      if (value) {
        return value.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      }

      return 0;
    },

    async loadCreatives($state, isFirstPage = false) {
      const { id } = this.$route.params;

      const creativesResponse = await CampaignAPI.getCampaignCreatives(
        id,
        isFirstPage ? 1 : this.pagination.page,
        this.pagination.limit,
        this.creativesFilters
      );
      const creatives = creativesResponse.getData([]);

      this.pagination = creatives.next;
      if (this.pagination.page !== -1) {
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }

      this.items = this.items.concat(
        creatives.items.map((item) => {
          return {
            ...item,
            open: false,
          };
        })
      );
    },
    async deleteCreative(creativeId) {
      await CampaignAPI.deleteCreative(
        this.$props.campaignId,
        parseInt(creativeId, 10) || -1
      );
      this.items = this.items.filter(
        (creativeItem) => creativeItem.id !== parseInt(creativeId, 10)
      );
    },
  },
  watch: {
    savedItems: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.items.push(newValue.slice(-1)[0]);
          this.$emit('popSavedItem');
        }
      },
      deep: true,
    },
    searchString: Utils.debounce(function (newValue) {
      this.items = [];
      this.creativesFilters.name = newValue;
      this.$emit('setIsTyping', false);
    }, 1000),

    isTyping: {
      handler(newValue) {
        this.loading = true;
        if (!newValue) {
          this.loadCreatives(null, true);
        }
      },
    },
  },
};
</script>

<style scoped>
.creative {
  padding-top: 0;
  margin-bottom: 10px;
}
.creative .content {
  transition: max-height 0.5s ease;
  overflow: hidden;
}
.creative .content.closed {
  max-height: 0;
}
.creative .content.opened {
  max-height: 500px;
}
.creative .content .infos {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px dashed #6f767e26;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.creative .content .infos:first-child {
  padding-top: 25px;
  margin-top: 0;
  border-top: 1px solid rgba(111, 118, 126, 0.15);
}
.creative .content .infos .info {
  display: flex;
  flex-direction: column;
}
.creative .content .infos .info:last-child {
  align-items: flex-end;
}
.creative .content .infos .info strong {
  font-weight: bold;
  color: var(--default);
}
.creative .content .infos .info p {
  color: #aaaaab;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.creative .content .infos .info :is(span, a) {
  color: rgba(190, 190, 191, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}
.creative .content .infos .info a {
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.65);
}
.creative .content .infos .info:last-child span {
  text-align: right;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
}
.header p {
  color: #aaaaab;
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
}
.value-sales {
  background: rgba(130, 217, 151, 0.05);
  display: flex;
  align-items: baseline;
  justify-content: center;
  border-radius: 10px;
  padding: 5px 15px;
  line-height: 17px;
  color: rgba(130, 217, 151, 0.75);
  font-size: 12px;
  font-weight: 300;
}
.value-sales strong {
  color: rgba(130, 217, 151, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}
.buttons-header {
  color: var(--icon-inactive);
  font-size: 12px;
  display: flex;
  align-items: center;
}
.buttons-header button {
  margin-left: 25px;
}
.buttons-header .fa-chevron-down {
  cursor: pointer;
  height: 35px;
  align-items: center;
  display: flex;
  transition: all 0.2s ease;
  padding: 0 25px;
  margin-right: -25px;
}
.buttons-header .open {
  border-left: 1px solid rgba(111, 118, 126, 0.3);
  margin-left: 25px;
}
.buttons-header .fa-chevron-down.rotate {
  transform: rotate(180deg);
}
.funnels {
  display: flex;
  align-items: center;
}
.funnels .line {
  border: none;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  height: 0px;
  width: 20px;
}
.funnel {
  border-radius: 10px;
  width: fit-content;
  display: flex;
  background-color: #292e32;
  padding: 10px 20px;
  min-width: 155px;
}
.funnel-value {
  color: #cbcbcb;
  border-bottom: none;
  display: flex;
  align-items: center;
}
.funnel-value p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 800;
}
.funnel-value p.empty {
  color: #898a8d;
  text-align: center;
  font-weight: normal;
}
.funnel-name {
  display: flex;
  color: #cccccc8c;
  border-right: 1px solid #2f3439;
  padding-right: 15px;
  margin-right: 15px;
}
.funnel-name p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}
.message {
  color: rgba(204, 204, 204, 0.55);
  font-size: 12px;
  line-height: 18px;
  background: #282d30;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-style: italic;
}

@media screen and (max-width: 991px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons-header {
    position: absolute;
    right: 0;
    top: 20px;
    padding-left: 20px;
  }
  .value-sales {
    margin-top: 10px;
  }
  .funnels {
    margin-bottom: 0;
    flex-direction: column;
    width: 100%;
  }
  .funnels .line {
    height: 0px;
    width: 10px;
    margin: auto;
    transform: rotate(90deg) translateX(-5px);
  }
  .funnels .funnel {
    width: 100%;
    text-align: center;
  }
  .funnels .funnel:not(:last-child) {
    margin-bottom: 10px;
  }
  .funnels .funnel .funnel-value {
    width: 100%;
    justify-content: center;
  }
  .funnels .funnel .funnel-name {
    min-width: 80px;
  }
  .funnels .funnel .funnel-value p {
    color: #cccccc;
    font-size: 14px;
  }
}

.empty-container {
  position: relative;
}
</style>
