import { render, staticRenderFns } from "./Creatives.vue?vue&type=template&id=61fe1ef1&scoped=true&"
import script from "./Creatives.vue?vue&type=script&lang=js&"
export * from "./Creatives.vue?vue&type=script&lang=js&"
import style0 from "./Creatives.vue?vue&type=style&index=0&id=61fe1ef1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fe1ef1",
  null
  
)

export default component.exports