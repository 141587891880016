<template>
  <base-box class="box-campaign" :key="campaign.id">
    <div class="header">
      <div class="d-flex align-center">
        <p class="field-title">{{ campaigns.name }}</p>
        <span class="tooltip" v-if="campaigns.tooltip">
          <i class="fas fa-exclamation" />
        </span>
      </div>
      <div class="tags">
        <base-badge
          v-if="campaigns.status === 'ACTIVE' && campaigns.target_average"
          :type="'reach'"
          :name="`${campaigns.target_average} alcance médio`"
          :absolute="false"
          class="mr_15"
        />
        <base-badge
          v-if="campaigns.status === 'ACTIVE' && !campaigns.target_average"
          :type="'default'"
          :name="'Em aprendizagem'"
          :absolute="false"
          class="mr_15"
        />
        <div>
          <base-badge
            v-if="campaigns.creatives"
            :type="'default'"
            :name="`${campaigns.creatives} criativos`"
            :absolute="false"
            class="mr_15"
          />
          <base-badge
            v-else
            :type="'default disable'"
            :name="'-- criativos'"
            :absolute="false"
            class="mr_15"
          />
        </div>
        <base-badge
          v-if="campaigns.initial_shot"
          :type="'reach'"
          :name="`${campaigns.initial_shot} alcance incial`"
          :absolute="false"
          class="mr_15"
        />

        <base-badge
          :type="getCampaignStatus(campaigns.status)"
          :name="getCampaignStatusName(campaigns.status)"
          :absolute="false"
        >
          <template slot="icon" v-if="campaigns.status === 'PROCESSING'">
            <i class="far fa-clock" />
          </template>
        </base-badge>
      </div>
    </div>

    <div class="content">
      <div class="funnels">
        <div class="funnel" :class="{ active: campaigns.sent }">
          <div class="d-flex align-center funnel-name">
            <p>Envios</p>
          </div>
          <div class="funnel-value">
            <p v-if="campaigns.sent">{{ campaigns.sent }}</p>
            <p v-else class="empty">---</p>
          </div>
        </div>
        <div class="line" :class="{ active: campaigns.clicks }"></div>
        <div class="funnel" :class="{ active: campaigns.clicks }">
          <div class="d-flex align-center funnel-name">
            <p>Cliques</p>
          </div>
          <div class="funnel-value">
            <p v-if="campaigns.clicks">{{ campaigns.clicks }}</p>
            <p v-else class="empty">---</p>
          </div>
        </div>
        <div class="line" :class="{ active: campaigns.conversions }"></div>
        <div class="funnel" :class="{ active: campaigns.conversions }">
          <div class="d-flex align-center funnel-name">
            <p>Conversões</p>
          </div>
          <div class="funnel-value">
            <p v-if="campaigns.conversions">
              {{ campaigns.conversions }}
            </p>
            <p v-else class="empty">---</p>
          </div>
        </div>
      </div>

      <div class="buttons">
        <base-button
          v-if="campaigns.step === 'CONTINUE_CREATION'"
          design="three mini"
          @click="() => null"
          >Continuar criação</base-button
        >
        <base-button
          v-if="
            (campaigns.config || []).includes('details') ||
            (campaigns.config || []).includes('details_block')
          "
          :disabled="(campaigns.config || []).includes('details_block')"
          design="three mini"
          @click="
            changeRoute('detalhes-campanha', {
              id: campaigns.id,
            })
          "
          >Ver detalhes</base-button
        >
        <base-button
          v-if="(campaigns.config || []).includes('creative')"
          design="three mini"
          @click="
            changeRoute('detalhes-campanha', {
              id: campaigns.id,
              creatives: true,
            })
          "
          >Configurar criativos</base-button
        >
        <base-button
          v-if="
            (campaigns.config || []).includes('edit') ||
            (campaigns.config || []).includes('edit_block')
          "
          :disabled="(campaigns.config || []).includes('edit_block')"
          design="three mini icon"
          @click="
            changeRoute('editar-campanha', {
              id: campaigns.id,
            })
          "
        >
          <i class="fal fa-pen" />
        </base-button>
        <base-button
          @click="confirmPublish"
          design="one mini icon"
          :disabled="(campaigns.config || []).includes('publish_block')"
          v-if="
            (campaigns.config || []).includes('publish') ||
            (campaigns.config || []).includes('publish_block')
          "
        >
          <i class="fa-regular fa-paper-plane-top"></i>
        </base-button>
        <base-button
          @click="resume"
          design="three mini icon"
          v-if="(campaigns.config || []).includes('resume')"
        >
          <i class="fa-regular fa-turn-down-left"></i>
        </base-button>
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      direction="center"
      title="O disparo inicial deve ser realizado?"
    >
      <p class="message-initial-firing">
        {{ publishMessage }}
      </p>

      <div class="buttons-firing">
        <div class="button-firing" @click="publish('INITIAL_SHOT')">
          <p>Realizar disparo inicial</p>
          <base-badge
            :absolute="false"
            :name="publishTargetMessage"
            type="purple"
          />
        </div>
        <div class="button-firing" @click="publish('WITHOUT_INITIAL_SHOT')">
          <p>Não realizar disparo inicial</p>
          <base-badge
            :absolute="false"
            name="0 envios"
            type="default disable"
          />
        </div>
      </div>
    </base-drawer>
  </base-box>
</template>
<script>
import dayjs from 'dayjs';
import BaseButton from '@/components/Button';
import BaseBox from '@/components/Box';
import BaseBadge from '@/components/Badge';
import BaseDrawer from '@/components/Drawer';
import CheckCampaign from '../../../plugins/core/schema/campaignsTypes';

import { EnvironmentAPI } from '@/api/Environment';

const components = {
  BaseButton,
  BaseBox,
  BaseBadge,
  BaseDrawer,
};

export default {
  components,
  props: {
    campaign: { type: Object },
  },
  data() {
    return {
      drawer: {
        open: false,
        close: true,
      },
      resumeLoading: false,
      campaigns: {},
    };
  },
  created() {
    this.campaigns = this.campaign;
    this.formatCampaigns(this.campaign);
  },
  watch: {
    campaign: {
      handler(newValue) {
        this.campaigns = newValue;
        this.formatCampaigns(this.campaigns);
      },
      deep: true,
    },
  },

  methods: {
    formatCampaigns(campaign) {
      const getFormatterCampaigns = new CheckCampaign(campaign);
      this.campaigns.config = getFormatterCampaigns.CampaignProcess().config;
    },

    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },

    getCampaignStatus(value) {
      const status = {
        ACTIVE: 'success',
        FINISHED: 'purple',
        UNDER_ANALYSIS: 'neutral_2',
        DRAFT: 'neutral',
        EXPIRED_DRAFT: 'neutral',
        ARCHIVED: 'neutral',
        SCHEDULED: 'neutral',
        PROCESSING: 'neutral',
        PAUSED: 'neutral',
      };

      return status[value] || 'exclamation';
    },
    getCampaignStatusName(value) {
      const status = {
        ACTIVE: 'Ativa',
        FINISHED: 'Encerrada',
        UNDER_ANALYSIS: 'Em análise',
        DRAFT: 'Rascunho',
        EXPIRED_DRAFT: 'Rascunho expirado',
        ARCHIVED: 'Arquivado',
        SCHEDULED: 'Enviando',
        PROCESSING: 'Enviando',
        PAUSED: 'Pausada',
      };

      return status[value] || 'Erro';
    },

    getStatusAfterResume(campaign) {
      if (campaign.status === 'PN') {
        return 'SCHEDULED';
      }

      return 'ACTIVE';
    },

    async resume() {
      const campaign = this.campaigns;
      const newStatus = this.getStatusAfterResume(campaign);
      const errorHandler = (message) =>
        alert(message || 'Não foi possível retomar a campanha');

      try {
        this.resumeLoading = true;
        const response = await EnvironmentAPI.updateCampaings(campaign.id, {
          status: newStatus,
        });

        const errors = response.getErrors([]);
        const hasError = errors.length > 0;
        if (hasError) {
          errorHandler(errors[0]);
        } else {
          this.$emit('changeStatus', {
            id: campaign.id,
            oldValue: 'PAUSED',
            newValue: newStatus,
          });
        }
      } catch (err) {
        errorHandler();
      } finally {
        this.resumeLoading = false;
      }
    },

    async confirmPublish() {
      const hasConfirmation = this.campaign.initial_flag > 1;
      if (hasConfirmation) {
        this.openPublishConfirmationDrawer();
      } else {
        await this.publish('WITHOUT_INITIAL_SHOT');
      }
    },

    openPublishConfirmationDrawer() {
      this.drawer.open = !this.drawer.open;
    },

    closePublishConfirmationDrawer() {
      this.drawer.close = !this.drawer.close;
    },

    async publish(type) {
      const newStatus = this.campaigns.type === 'PN' ? 'SCHEDULED' : 'ACTIVE';
      let shouldBeRemoveAnalysis = false;

      if (type === 'WITHOUT_INITIAL_SHOT') {
        shouldBeRemoveAnalysis = true;
      }

      this.closePublishConfirmationDrawer();

      try {
        if (shouldBeRemoveAnalysis) {
          const response = await EnvironmentAPI.updateCampaings(
            this.campaign.id,
            {
              initial_flag: 1,
            }
          );
          const errors = response.getErrors([]);
          const hasError = errors.length > 0;
          if (hasError) {
            alert(errors[0]);
            return;
          }
        }

        const response = await EnvironmentAPI.updateCampaings(
          this.campaign.id,
          { status: newStatus }
        );
        const errors = response.getErrors([]);
        const hasError = errors.length > 0;
        if (hasError) {
          alert(errors[0]);
        } else {
          this.$emit('changeStatus', {
            id: this.campaigns.id,
            oldValue: 'DRAFT',
            newValue: newStatus,
          });
        }
      } catch {
        alert('Não foi possível publicar está campanha');
      }
    },
  },

  computed: {
    publishMessage() {
      const analysisDate = this.campaigns.created_at;
      const analysisDateFormatted = dayjs(analysisDate).format('DD/MM/YYYY');
      const flag = Number(this.campaigns.initial_flag);

      let extraInfo = '';

      if (flag === 2 && this.campaigns.configs.createdAtMonthsBefore) {
        extraInfo = `considerando apenas clientes que pediram nos últimos ${this.campaigns.configs.createdAtMonthsBefore} meses`;
      } else {
        extraInfo = `considerando todo o período`;
      }

      return `Alcance inicial calculado no dia ${analysisDateFormatted}, ${extraInfo}.`;
    },

    publishTargetMessage() {
      return `${this.campaigns.initial_shot || 0} envios`;
    },
  },
};
</script>
<style scoped>
.box-campaign {
  border-radius: 10px;
  position: relative;
  margin-bottom: 15px;
}
.box-campaign .header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #ffffff17;
  padding-bottom: 20px;
}
.campaign-action {
  color: var(--label-secondary);
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
}
.tooltip {
  background: rgba(255, 241, 173, 0.2);
  width: 17px;
  height: 17px;
  margin-left: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff1ad;
  font-size: 6px;
}
.box-campaign .header .tags {
  display: flex;
}
.field-title {
  color: var(--label-secondary);
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.funnels {
  display: flex;
  align-items: center;
}
.funnel {
  border-radius: 10px;
  width: fit-content;
  display: flex;
  background-color: #282d31;
  padding: 10px 20px;
  min-width: 155px;
}
.funnel.active {
  background-color: #292e32;
}
.funnel-value {
  color: #cbcbcb;
  border-bottom: none;
  display: flex;
  align-items: center;
}
.funnel-value p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 800;
}
.funnel-value p.empty {
  color: #898a8d;
  text-align: center;
  font-weight: normal;
}
.funnel-name {
  color: #cccccc8c;
  border-right: 1px solid #2f3439;
  padding-right: 15px;
  margin-right: 15px;
}
.funnel-name p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}
.funnels .line {
  border: none;
  border-bottom: 1px solid var(--box-up);
  height: 0px;
  width: 20px;
}
.buttons {
  display: flex;
}
.buttons button:first-child {
  flex: 1;
}
.buttons button:not(:last-child) {
  margin-right: 15px;
}
.message-initial-firing {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ebebeb;
}
.buttons-firing {
  display: flex;
  margin-top: 35px;
}
.buttons-firing .button-firing {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #343b40;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
  cursor: pointer;
}
.buttons-firing .button-firing:hover {
  background: #292f33;
}
.buttons-firing .button-firing:last-child {
  margin-left: 20px;
}
@media screen and (max-width: 991px) {
  .box-campaign .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .box-campaign .header .tags {
    margin-top: 10px;
  }
  .field-title {
    font-size: 14px;
    line-height: 17px;
  }
  .buttons {
    width: 100%;
  }
  .buttons button:first-child {
    flex: 1;
  }
  .funnel {
    padding: 10px 20px;
  }
  .funnels {
    margin-bottom: 10px;
    flex-direction: column;
    width: 100%;
  }
  .funnels .line {
    height: 0px;
    width: 10px;
    margin: auto;
    transform: rotate(90deg) translateX(-5px);
  }
  .funnels .funnel {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .funnels .funnel .funnel-value {
    width: 100%;
  }
  .funnel .funnel-name {
    justify-content: center;
  }
  .funnel-value p.empty {
    width: 100%;
    justify-content: center;
  }
  .content {
    flex-direction: column;
  }
  .buttons-firing {
    flex-direction: column;
  }
  .buttons-firing .button-firing:last-child {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
